<template>
<AddDiscountModule
  @saveDetail="saveDiscount"
  @newProductAdd = "newProductAdd"
  @cancelCreate="cancelCreate"
  @deleteTempItem = "deleteTempItem"
  :detail="detail"
  :update="false"
/>
</template>

<script>
import AddDiscountModule from "../../components/webstore-discounts/AddDiscountModule";
import {mapActions} from "vuex";
import _ from 'lodash';

export default {
  name: "AddDiscount",
  components:{
    AddDiscountModule
  },
  data: () => ({
    redirectRoute:'DiscountManagement',
    detail: {
      name: '',
      discountType:'SpecificProduct',
      productDiscounts: [],
      checkoutLabel: ''
    }
  }),
  methods:{
    ...mapActions("discount", ["addDiscount"]),
    newProductAdd(product){
       if(_.find(this.detail.productDiscounts,{productStoreId:product._id})){
         this.$vs.notify({
           title: "Product Add",
           text: "Product has been already added below.",
           color: "danger",
         });
         return false;
       }
      this.detail.productDiscounts.push({
        name:product.name,
        productStoreId:product._id,
        price:product.price/100,
        isActive:true,
        adjustedPrice:0
      })
    },
    saveDiscount(data){
      let self=this;
      this.addDiscount(data).then(res => {
        self.$vs.notify({
          title: "Discount Add",
          text: "Discount added successfully",
          color: "success",
        });
        self.$vs.loading.close();
        this.$router.push({ name: this.redirectRoute})
      });
    },
    cancelCreate() {
      this.$router.push({ name: this.redirectRoute});
    },
    async deleteTempItem(discount) {
        const id = discount.productStoreId
        const arr = this.detail.productDiscounts

        const productIndex = _.findIndex(arr, { productStoreId: id });
        if (productIndex !== -1) {
            arr.splice(productIndex, 1);
        }
    },
  }
}
</script>

<style scoped>

</style>
